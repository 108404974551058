import React from 'react'

import { Box, Heading, Text, Button } from '@chakra-ui/core'

export default () => {
  return (
    <Box>
      <Heading pt={10}>
        รู้ไว้ไม่พลาด! 10 อันดับมหาวิทยาลัยในประเทศอังกฤษ ที่ต้องไป
        ถ้าอยากได้งาน! - Marketing
      </Heading>
      <Text py={4}>
        จากผลสำรวจพบว่า ประเทศอังกฤษ
        คือประเทศที่ได้รับความนิยมมากที่สุดสำหรับการศึกษาต่อในต่างประเทศ
        สาเหตุหนึ่งเป็นเพราะการเรียนต่อในระดับปริญญาโทนั้น
        ใช้เวลาทั้งสิ้นเพียงแค่ 1 ปีเท่านั้นในการจบหลักสูตร
        ซึ่งนับว่าเป็นการประหยัดทั้งเวลาและค่าใช้จ่ายในการเรียนมาก
      </Text>
      <Text py={4}>
        นอกจากนั้น การศึกษาในประเทศอังกฤษยังสามารถรับรองได้ถึงความเข้มข้น
        และคุณภาพของการศึกษา ด้วยลักษณะวิธีการสอนเชิงประยุกต์
        ที่เน้นให้นักศึกษาได้วิเคราะห์เนื้อหา ต่อยอดความคิด
        และเรียนรู้ด้วยตัวเอง ผ่านการบ้านและโปรเจกต์ที่มอบหมายให้ทำนอกเวลาเรียน
      </Text>
      <Text py={4}>
        ที่สำคัญที่สุด ประเทศอังกฤษยังเป็น 1
        ในไม่กี่ประเทศที่มอบทุนจำนวนมากให้กับนักเรียนต่างชาติ
        ที่ต้องการศึกษาต่อภายในประเทศของตนอีกด้วย
      </Text>
      <Text py={4}>
        บอกทุนแอบกระซิบว่า ท้ายบทความนั้น เรามีเอกสาร checklist เตรียมความพร้อม
        สำหรับการขอทุนไปเรียนต่อต่างประเทศ สำหรับผู้ที่สนใจ
        มาให้ได้ดาวน์โหลดกันไปใช้เตรียมความพร้อมกันฟรี ๆ อีกด้วย!
      </Text>
      <Text py={4}>
        และสำหรับใครที่กำลังมองหาโอกาสในการเรียนต่อประเทศอังกฤษ โดยเฉพาะสาย
        Marketing วันนี้ บอกทุนได้รวบรวม 10 อันดับรายชื่อมหาวิทยาลัยชั้นนำ
        สำหรับเหล่าหัวการตลาด ที่อยากทำงานในสาย Marketing มาให้ได้อ่านกัน
      </Text>
      <Text py={4}>
        ซึ่งข้อมูลที่นำมาอ้างอิงนั้น บอกทุนได้รวบรวมมาจากเว็บไซต์ LinkedIn
        โซเชียลมีเดียแพลตฟอร์มขนาดใหญ่สำหรับเหล่าคนทำงานทั้งหลาย
        โดยจัดเรียงอันดับตามจำนวนศิษย์เก่าของมหาวิทยาลัย
        ที่ได้ทำงานในบริษัทยักษ์ใหญ่ทางด้าน marketing ทั้งหลาย ไม่ว่าจะเป็น
        Unilever, Protect&Gamble, Google หรือ Loreal
      </Text>
      <Text py={4}>ถ้าพร้อมแล้ว ไปดูกันเลย!</Text>

      <ol>
        <li>The London School of Economics and Political Science (LSE)</li>
        <li>Aston University University of Bath University of Surrey Durham</li>
        <li>
          University King’s college Lonon University of Warwick University of
        </li>
        <li>Oxford University of College London University of Nottingham</li>
      </ol>
      <Text py={4}>
        สำหรับใครที่สนใจจะไปเรียนต่อต่างประเทศ ไม่ว่าจะเป็นประเทศอังกฤษ
        สหรัฐอเมริกา ญี่ปุ่น ออสเตรเลีย หรืออื่น ๆ สามารถดาวน์โหลด checklist
        เตรียมความพร้อมสำหรับการขอทุนได้ฟรี ๆ ได้ที่นี่
      </Text>
      <Button color='white' bg='#F98770' borderRadius={30}>
        Download
      </Button>
      <Text py={4}>บอกทุน..เพราะอยากให้คุณได้เรียนต่อ</Text>
    </Box>
  )
}
